import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Slider from "react-slick";

const BannerWrap = styled.div`
  font-size: 0;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
`;
const BannerImage = styled.img`
  width: 100%;
`;
const BannerItem = styled.div``;

const sliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

const adList = [
  {
    image: "/assets/images/banner-cu.png",
    url: "https://blog.naver.com/secondplanteam/222969089674",
    alt: "바로택배에서 가장 저렴한 운임으로 CU편의점택배를 이용하세요!",
  },
  {
    image: "/assets/images/banner-gs.png",
    url: "https://blog.naver.com/secondplanteam/222969089674",
    alt: "GS25 편의점에서 초특가 택배비를 만나보세요.",
  },
];

const shuffleArr = (arr) => {
  let currentIndex = arr.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = arr[currentIndex];
    arr[currentIndex] = arr[randomIndex];
    arr[randomIndex] = temporaryValue;
  }
  return arr;
};

const Banner = memo(() => {
  return (
    <BannerWrap>
      <Slider {...sliderSettings}>
        {shuffleArr(adList).map((el, idx) => {
          return (
            <BannerItem key={idx}>
              {el.url ? (
                <Link
                  to={{
                    pathname: el.url,
                  }}
                  target="_blank"
                >
                  <BannerImage src={el.image} alt={el.alt} />
                </Link>
              ) : (
                <BannerImage src={el.image} alt={el.alt} />
              )}
            </BannerItem>
          );
        })}
      </Slider>
    </BannerWrap>
  );
});

export default Banner;
