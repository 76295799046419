import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { withQueryParams, StringParam } from "use-query-params";

import { PICKUP_LOCATIONS, PICKUP_LOCATION_ETC } from "../constants";

import { useDeliveryStore, useNavStore } from "../stores/hooks";

import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Radio from "../components/Radio";

import { captureException } from "../utils/Sentry";

const BodyContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #202020;
  color: #fff;

  ${({ navOpen }) =>
    navOpen &&
    `
      position: fixed;
      width: 100%;
      height: 100vh;
    `}
`;

const BgTriangle = styled.div`
  width: 100%;
  height: 180px;
  background-image: linear-gradient(
    90deg,
    rgba(234, 40, 69, 1) 0%,
    rgba(243, 71, 78, 1) 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 40px solid transparent;
    border-right: 100vw solid #202020;
    position: absolute;
    left: 0;
    top: 140px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;

const Highlight = styled.span`
  color: #75ff6f;

  ${({ small }) =>
    small &&
    `
    font-weight: normal;
    font-size: 14px;
  `}
`;

const Bold = styled.b``;

const SectionTitle = styled.div`
  padding-bottom: 20px;
`;
const PreTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;
const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
`;

const SectionForm = styled.div``;
const FormIntro = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;
`;
const IntroIconWrap = styled.div`
  width: 110px;
  height: 100px;
  padding-top: 17px;
  border-radius: 4px;
  border: solid 1px #333333;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;
const IntroIcon = styled.img`
  height: 47px;
`;
const IntroIconText = styled.div`
  font-size: 16px;
  text-align: center;
  color: #75ff6f;
  margin-top: 4px;
`;
const IntroDesc = styled.div`
  font-size: 16px;
  margin-top: 8px;

  ${({ highlight }) =>
    highlight &&
    `
    color: #75ff6f;
  `}
`;

const FormGroup = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid #333;

  ${({ first }) =>
    first &&
    `
    padding-bottom: 30px;
    border-bottom: none;
  `}
`;
const Label = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;
const Input = styled.input`
  resize: none;
  width: 100%;
  height: 26px;
  background-color: #333;
  padding: 0 7px;
  font-size: 16px;
  color: #fff;
  border: none;
  margin-left: 13px;

  &::placeholder {
    color: #999999;
  }
`;
const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  height: 54px;
  background-color: #333;
  padding: 15px 10px;
  font-size: 16px;
  color: #fff;
  border: none;

  &::placeholder {
    color: #999999;
  }
`;
const RadioWithInputWrap = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #333;
`;

const FromNotice = styled.div`
  font-size: 16px;
  color: #75ff6f;
  padding: 16px 0 24px;
`;

const FormSubmit = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #333;
`;
const Button = styled.button`
  width: 100%;
  height: 50px;
  background-color: #75ff6f;
  border: none;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #202020;
`;

const SectionBanner = styled.div`
  padding-top: 24px;
`;

function Index({ query }) {
  const { token } = query;

  const { body, delivery } = useDeliveryStore().state;
  const { ...actions } = useDeliveryStore();

  const { navActive } = useNavStore().state;

  useEffect(() => {
    fetchByToken();
  }, []);

  useEffect(() => {
    if (delivery.errorStatus) {
      alert(
        `해당 접수건은 ${delivery.errorStatus} 상태로 수령희망장소를 요청하실 수 없습니다. 착배송 혹은 구매처로 문의 부탁드립니다. 감사합니다.`,
      );
    }
  }, [delivery]);

  const fetchByToken = useCallback(async () => {
    if (token) {
      try {
        await actions.fetchByToken({ token });
      } catch (e) {
        captureException(e);

        if (e.status > 500) {
          alert("배송정보를 가져오는데 실패했습니다. 다시 시도해주세요.");
        } else {
          alert(
            "배송정보를 가져올 수 없습니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.",
          );
        }
      }
    } else {
      alert("잘못된 접근입니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.");
    }
  }, [actions.fetchByToken, token]);

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "pickupLocationText" && e.target.value) {
        const korean = /[ㄱ-힣]/g;
        const text = /[ㄱ-힣a-zA-Z0-9 ]/g;
        const pickupLocationText = e.target.value;

        const textKorean = pickupLocationText.match(korean);
        const validedText = pickupLocationText.match(text);

        if (validedText) {
          if (
            pickupLocationText.length + (textKorean ? textKorean.length : 0) <=
            20
          ) {
            actions.setBody({
              [e.target.name]: validedText.join(""),
            });
          } else {
            alert("한글 10자 또는 영문 20자로 입력해주세요.");
          }
        }
      } else if (e.target.name === "doorKey" && e.target.value) {
        const text = /[ㄱ-힣a-zA-Z0-9*#]/g;
        const doorKeyText = e.target.value;

        const validedText = doorKeyText.match(text);

        if (validedText) {
          if (validedText.length > 20) {
            alert("20자 이하로 입력해주세요.");
          } else {
            actions.setBody({
              [e.target.name]: validedText.join(""),
            });
          }
        }
      } else {
        actions.setBody({
          [e.target.name]: e.target.value,
        });
      }
    },
    [actions.setBody],
  );

  const handleChangeIsDoorKey = useCallback(
    (value) => {
      if (value !== body.isDoorKey) {
        actions.setBody({
          isDoorKey: value,
          doorKey: "",
        });
      }
    },
    [actions.setBody, body.isDoorKey],
  );

  const handleChangePickupLocation = useCallback(
    (value) => {
      if (value !== body.pickupLocation) {
        actions.setBody({
          pickupLocation: value,
          pickupLocationText: "",
        });
      }
    },
    [actions.setBody, body.pickupLocation],
  );

  const handleSubmit = useCallback(async () => {
    let _body = { ...body };

    if (!token || !delivery.bookId) {
      alert("잘못된 접근입니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.");
    } else if (delivery.errorStatus) {
      alert(
        `해당 접수건은 ${delivery.errorStatus} 상태로 수령희망장소를 요청하실 수 없습니다. 착배송 혹은 구매처로 문의 부탁드립니다. 감사합니다.`,
      );
    } else if (_body.isDoorKey && !_body.doorKey) {
      alert("공동현관 비밀번호를 입력해주세요.");
    } else if (
      _body.pickupLocation === PICKUP_LOCATION_ETC &&
      !_body.pickupLocationText
    ) {
      alert("수령 희망장소를 입력해주세요.");
    } else {
      try {
        let data = {
          token,
          place:
            _body.pickupLocation === PICKUP_LOCATION_ETC
              ? _body.pickupLocationText
              : _body.pickupLocation,
        };

        if (_body.isDoorKey) {
          data.frontdoorPassword = _body.doorKey;
        }

        await actions.updateDelivery(delivery.bookId, data);

        alert(
          "수령희망장소요청을 완료했습니다. 요청은 1회만 가능하며 상황에 따라 요청 장소에 배송되지 않을 수 있는 점 양해 부탁드리겠습니다. 안전하고 빠르게 배송하겠습니다!",
        );

        actions.resetBody();
      } catch (e) {
        captureException(e);
        alert("수령희망장소 입력에 실패했습니다. 다시 시도해주세요.");
      }
    }
  }, [actions.updateDelivery, actions.resetBody, body, delivery, token]);

  return (
    <BodyContent navOpen={navActive}>
      <BgTriangle />
      <Nav />
      <SectionTitle>
        <Container>
          <PreTitle>4시간 오늘도착 배송혁명</PreTitle>
          <Title>
            수령 희망장소
            <br />
            요청하기
          </Title>
        </Container>
      </SectionTitle>
      <SectionForm>
        <Container>
          <FormIntro>
            <Link to={{ pathname: "https://chak-check.kr/" }} target="_blank">
              <IntroIconWrap>
                <IntroIcon
                  src="/assets/images/icon-place.svg"
                  alt="수령 희망장소 요청 아이콘"
                />
                <IntroIconText>배송조회 </IntroIconText>
              </IntroIconWrap>
            </Link>
            <IntroDesc>
              <Highlight>{delivery.receiverName}</Highlight> 고객님,{" "}
              <Highlight>{delivery.displaySenderName}</Highlight>에서 보낸
              물품이 배송 중입니다. 원하시는 수령 장소를 선택해주시면 더욱{" "}
              <Highlight>안전! 신속! 정확한</Highlight> 배송을 도와드리겠습니다.
            </IntroDesc>
            <IntroDesc highlight>
              *현관 앞, 기타장소는 분실 위험이 있으니 빠른 시간 안에 수령하시기
              바랍니다.
            </IntroDesc>
          </FormIntro>
          <FormGroup first>
            <Label>
              <Highlight>공동현관</Highlight> 비밀번호가 있습니까?
              <br />
              <Highlight small>
                <Bold>안전한 배송</Bold>을 위해 공동현관 비밀번호를 꼭
                알려주세요!
                <br />
                (특수문자는 *, #만 사용, 글자수는 20글자 이내)
              </Highlight>
            </Label>
            <RadioWithInputWrap>
              <Radio
                active={body.isDoorKey}
                onClick={() => {
                  handleChangeIsDoorKey(true);
                }}
                name="isDoorKey"
                value={true}
                noBorder
              >
                예
              </Radio>
              <Input
                placeholder="비밀번호 입력"
                disabled={!body.isDoorKey}
                value={body.doorKey}
                name="doorKey"
                onChange={handleChange}
              />
            </RadioWithInputWrap>
            <Radio
              active={!body.isDoorKey}
              onClick={() => {
                handleChangeIsDoorKey(false);
              }}
              name="isDoorKey"
              value={false}
            >
              아니요
            </Radio>
          </FormGroup>
          <FormGroup>
            <Label>수령 희망장소를 선택해주세요.</Label>
            {PICKUP_LOCATIONS.map((location) => {
              return (
                <Radio
                  key={location.value}
                  active={body.pickupLocation === location.value}
                  onClick={() => {
                    handleChangePickupLocation(location.value);
                  }}
                >
                  {location.displayText || location.value}
                </Radio>
              );
            })}
            <Radio
              active={body.pickupLocation === PICKUP_LOCATION_ETC}
              onClick={() => {
                handleChangePickupLocation(PICKUP_LOCATION_ETC);
              }}
            >
              기타 (입력 제한: 한글 10자 또는 영문 20자)
            </Radio>
            <Textarea
              placeholder="내용을 입력해주세요."
              disabled={body.pickupLocation !== PICKUP_LOCATION_ETC}
              value={body.pickupLocationText}
              name="pickupLocationText"
              onChange={handleChange}
            />
          </FormGroup>
          <FromNotice>
            * 수령 희망 장소 신청은 배송완료 전까지
            <br />
            가능하며, 상황에 따라 신청 장소에 배송되지
            <br />
            않을 수 있습니다.
          </FromNotice>
          <FormSubmit>
            <Button type="button" onClick={handleSubmit}>
              요청하기
            </Button>
          </FormSubmit>
        </Container>
      </SectionForm>
      <SectionBanner>
        <Container>
          <Banner />
        </Container>
      </SectionBanner>
      <Footer />
    </BodyContent>
  );
}

export default withQueryParams({ token: StringParam }, Index);
